import React, {
  FC,
  useState,
  Children,
  isValidElement,
  ReactNode,
  cloneElement,
} from "react";

interface Props {
  children: ReactNode;
  initialIndex?: number;
  className?: string;
}

const Collapsible: FC<Props> = ({ children, initialIndex = -1, className }) => {
  const [activeIndex, setActiveIndex] = useState<number>(initialIndex);

  const handleClick = (index: number) => {
    const newIndex = index === activeIndex ? -1 : index;

    setActiveIndex(newIndex);
  };

  const renderItem = (child: ReactNode, index: number) => {
    if (!isValidElement(child)) {
      return child;
    }

    const childProps = {
      index,
      isActive: index === activeIndex,
      onClick: handleClick,
    };

    return cloneElement(child, childProps);
  };

  return <div className={className}>{Children.map(children, renderItem)}</div>;
};

export default Collapsible;
