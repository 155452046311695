export enum Routes {
  // internal
  FORM_SELF = "/",
  FORM_SELF_MORE = "/more",
  FORM_SUCCESS = "/success",
  FORM_FAILURE = "/failure",
  FORM_REPORT_ERROR = "/reportError",
  DECLARATION_HISTORY = "/declarationHistory",
  CONNECTION_LOST = "/connectionLost",
  SESSION_EXPIRED = "/sessionExpired",
  LIMIT_EXCEEDED = "/limitExceeded",

  // external
  INFOGRAPHICS_CNA = "https://infographics.channelnewsasia.com/wuhan/gmap.html",
}
