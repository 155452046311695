import classnames from "classnames/bind";
import React, { FC } from "react";

import styles from "./style.module.scss";

const cx = classnames.bind(styles);

export enum SvgPeopleExpression {
  NEUTRAL = "neutral",
  WELL = "well",
  UNWELL = "unwell",
}

interface Props {
  expression: SvgPeopleExpression;
  [rest: string]: any;
}

const SvgPeople: FC<Props> = ({ expression, ...rest }) => {
  const neutralClass = cx({
    neutral: true,
    show: expression === SvgPeopleExpression.NEUTRAL,
  });

  const wellClass = cx({
    well: true,
    show: expression === SvgPeopleExpression.WELL,
  });

  const unwellClass = cx({
    unwell: true,
    show: expression === SvgPeopleExpression.UNWELL,
  });

  const faceClass = cx({
    face: true,
    flush: expression === SvgPeopleExpression.UNWELL,
  });

  return (
    <svg
      data-testid="svg-people"
      width="320"
      height="160"
      viewBox="0 0 320 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="31"
        y="-14"
        width="168"
        height="168"
      >
        <circle r="84" transform="matrix(-1 0 0 1 115 70)" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M216.443 114.889C216.443 103.338 173.152 7.26689 116.939 8.97351C38.9566 11.3411 18.9564 132.222 22.5266 144.121C25.3689 153.596 39.0668 157.042 99.1236 155.363C189.326 152.84 216.443 125.233 216.443 114.889Z"
          fill="#133D99"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.438 136.312C111.438 136.312 123.309 136.663 128.369 133.899L112.069 144.133L111.438 136.312Z"
          fill="#CF3E83"
        />
        <path
          className={faceClass}
          d="M113.577 57.6887C99.8945 64.5983 78.3467 77.2692 67.3881 93.077C69.3059 106.725 74.9109 124.109 85.9019 131.417C91.9518 135.438 102.05 136.852 111.441 136.31L113.589 162.97L143.315 153.119C143.315 153.119 140.368 121.879 139.092 110.578C145.252 104.987 156.663 101.48 156.682 91.9875C156.682 88.7101 154.764 83.0183 147.675 83.0091C138.166 82.9999 139.514 94.3927 139.514 94.3927C129.901 98.136 120.083 69.625 113.577 57.6887Z"
          fill="#FFB536"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.773 92.0713L97.9798 108.541L105.862 108.383L104.773 92.0713Z"
          fill="#405FD3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.023 92.7853C111.193 96.0755 113.127 98.6387 115.345 98.5111C117.563 98.3835 119.224 95.6129 119.054 92.3226C118.885 89.0324 116.95 86.4692 114.732 86.5969C112.514 86.7245 110.853 89.4951 111.023 92.7853Z"
          fill="#0D0B6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M89.3425 92.2601C89.5123 95.5503 91.4475 98.1135 93.6646 97.9859C95.8825 97.8583 97.5442 95.0876 97.3743 91.7974C97.2045 88.5072 95.2702 85.9431 93.0523 86.0707C90.8343 86.1983 89.1736 88.9699 89.3425 92.2601Z"
          fill="#0D0B6D"
        />
        <path
          opacity="0.398182"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.458 136.311C111.458 136.311 123.329 136.662 128.388 133.898L112.089 144.132L111.458 136.311Z"
          fill="#405FD3"
        />
      </g>
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="140"
        y="-14"
        width="169"
        height="168"
      >
        <circle cx="224.714" cy="70" r="84" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M272.906 57.0283C272.906 70.4871 264.42 81.3971 253.952 81.3971C243.485 81.3971 234.999 70.4871 234.999 57.0283C234.999 43.5696 243.485 32.6595 253.952 32.6595C264.42 32.6595 272.906 43.5696 272.906 57.0283Z"
          fill="#133D99"
        />
        <path
          className={faceClass}
          d="M227.407 153.27L226.601 125.704C226.601 125.704 260.402 128.371 267.633 124.649C267.633 124.649 276.47 79.5251 265.93 54.537L265.717 53.564L194.388 45.2953L190.389 85.8597C187.986 78.8361 168.156 70.8719 162.973 80.9296C151.74 102.723 191.904 117.398 191.904 117.398L187.826 153.601"
          fill="#FFB536"
        />
        <path
          opacity="0.398926"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M225.974 124.485L204.312 119.304L225.87 135.55L225.974 124.485Z"
          fill="#405FD3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M244.013 85.0079L253.05 102.947L242.219 103.059L244.013 85.0079Z"
          fill="#405FD3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M236.219 85.7536C236.717 88.5082 234.888 91.1449 232.134 91.6425C229.379 92.1411 226.742 90.3116 226.244 87.5579C225.746 84.8034 227.575 82.1667 230.329 81.6681C233.084 81.1705 235.72 82.9991 236.219 85.7536Z"
          fill="#0D0B6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M263.736 85.7536C264.234 88.5082 262.405 91.1449 259.651 91.6425C256.896 92.1411 254.259 90.3116 253.762 87.5579C253.263 84.8034 255.092 82.1667 257.847 81.6681C260.601 81.1705 263.237 82.9991 263.736 85.7536Z"
          fill="#0D0B6D"
        />
        <path
          d="M195.175 92.2279C188.449 80.6856 178.719 77.1655 174.352 76.1332C172.61 71.0844 171.282 65.229 170.349 59.0016C167.847 42.3346 179.931 27.1282 189.692 22.7617C199.573 18.3428 216.609 12.2192 241.546 20.5315C258.311 26.1238 269.028 12.7353 278.893 18.4534C295.751 28.2272 280.071 71.0173 237.325 60.1471C206.748 52.3711 205.962 54.3217 205.7 59.9739C204.852 78.244 202.011 88.9921 195.175 92.2279Z"
          fill="#133D99"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M231.388 77.6028C226.456 77.6028 222.449 81.3188 222.449 85.9103C222.449 90.5016 226.456 94.217 231.388 94.217C236.322 94.217 240.328 90.5016 240.328 85.9103C240.328 81.3189 236.322 77.6028 231.388 77.6028ZM218.753 85.9103C218.753 79.4338 224.407 74.1768 231.388 74.1768C238.371 74.1768 244.025 79.4337 244.025 85.9103C244.025 92.3872 238.371 97.6431 231.388 97.6431C224.407 97.6431 218.753 92.3871 218.753 85.9103Z"
          fill="#0D0B6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258.466 77.6028C253.532 77.6028 249.526 81.3189 249.526 85.9103C249.526 90.5016 253.532 94.217 258.466 94.217C263.398 94.217 267.405 90.5016 267.405 85.9103C267.405 81.3188 263.398 77.6028 258.466 77.6028ZM245.83 85.9103C245.83 79.4337 251.484 74.1768 258.466 74.1768C265.447 74.1768 271.101 79.4338 271.101 85.9103C271.101 92.3871 265.447 97.6431 258.466 97.6431C251.484 97.6431 245.83 92.3872 245.83 85.9103Z"
          fill="#0D0B6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M242.219 85.0079H247.635V88.6181H242.219V85.0079Z"
          fill="#0D0B6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.482 83.2029H220.558V88.6182H193.482V83.2029Z"
          fill="#0D0B6D"
        />
      </g>

      <g className={neutralClass}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.7896 120.798C98.7907 120.798 98.7918 120.799 98.1853 122.402C97.5787 124.005 97.5799 124.006 97.5811 124.006L97.5837 124.007L97.5894 124.009L97.6029 124.014L97.6383 124.027C97.6657 124.037 97.7005 124.048 97.7426 124.062C97.8266 124.089 97.9397 124.124 98.0799 124.161C98.36 124.235 98.75 124.322 99.2344 124.389C100.203 124.523 101.555 124.575 103.16 124.282C106.413 123.687 110.511 121.705 114.486 116.525L111.766 114.438C108.264 119.001 104.891 120.48 102.543 120.909C101.348 121.128 100.366 121.084 99.7021 120.993C99.3699 120.947 99.1184 120.889 98.9612 120.848C98.8828 120.827 98.8282 120.81 98.7992 120.8L98.7755 120.792L98.7753 120.792C98.7753 120.792 98.7764 120.793 98.7786 120.794L98.7839 120.796L98.7876 120.797L98.7896 120.798Z"
          fill="#0D0B6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M237.095 110.691C237.094 110.69 237.094 110.689 238.944 109.522C240.794 108.356 240.793 108.355 240.793 108.354L240.792 108.352L240.791 108.35L240.789 108.346C240.788 108.345 240.788 108.344 240.788 108.344C240.788 108.344 240.788 108.345 240.789 108.345L240.803 108.373C240.818 108.403 240.846 108.457 240.887 108.53C240.969 108.678 241.1 108.905 241.282 109.183C241.648 109.743 242.206 110.497 242.959 111.244C244.454 112.729 246.67 114.151 249.714 114.081L249.798 118.927C245.45 119.026 242.26 116.957 240.197 114.91C239.171 113.89 238.412 112.866 237.905 112.091C237.651 111.702 237.458 111.371 237.324 111.128C237.256 111.007 237.204 110.907 237.166 110.833C237.147 110.796 237.131 110.765 237.119 110.74L237.104 110.709L237.098 110.698L237.096 110.693L237.095 110.691Z"
          fill="#0D0B6D"
        />
      </g>

      <g className={wellClass}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.9101 123.089C100.355 125.929 104.562 127.629 109.308 126.886C114.053 126.143 117.392 122.173 116.947 119.333C116.502 116.493 111.328 118.96 106.583 119.703C101.837 120.446 99.465 120.249 99.9101 123.089Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.934 114.089C253.489 116.929 249.282 118.629 244.536 117.886C239.791 117.143 236.452 113.173 236.897 110.333C237.342 107.493 242.516 109.96 247.261 110.703C252.007 111.446 254.379 111.249 253.934 114.089Z"
          fill="white"
        />
      </g>

      <g className={unwellClass}>
        <path
          d="M70.6469 73.0481L93.63 106.3C93.9838 106.812 94.2804 107.361 94.5147 107.937L98.2237 117.061L92.45 121.051L85.226 114.357C84.7696 113.934 84.3609 113.463 84.0072 112.951L61.0241 79.6992C59.6853 77.7623 60.1702 75.1067 62.1072 73.7679L64.7156 71.965C66.6526 70.6262 69.3081 71.1111 70.6469 73.0481Z"
          fill="#0D0B6D"
        />
        <path
          d="M69.2676 77.2245C68.9329 76.7402 68.2691 76.619 67.7848 76.9537L65.6893 78.4021C65.2051 78.7368 65.0838 79.4007 65.4185 79.8849L72.1878 89.6787C72.5225 90.1629 73.1864 90.2842 73.6707 89.9495L75.7662 88.5011C76.2504 88.1664 76.3717 87.5025 76.037 87.0182L69.2676 77.2245Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.394 119.197C105.393 119.197 105.392 119.196 106.224 117.697C107.056 116.199 107.055 116.198 107.053 116.197L107.051 116.196L107.046 116.193L107.033 116.186L107 116.169C106.974 116.155 106.941 116.138 106.902 116.119C106.822 116.08 106.716 116.029 106.582 115.972C106.316 115.858 105.943 115.716 105.473 115.579C104.533 115.307 103.204 115.06 101.573 115.118C98.2682 115.236 93.9271 116.605 89.2444 121.156L91.634 123.615C95.7593 119.605 99.3104 118.63 101.696 118.544C102.91 118.501 103.875 118.686 104.519 118.873C104.841 118.966 105.082 119.059 105.231 119.123C105.306 119.155 105.357 119.18 105.385 119.193L105.407 119.205L105.407 119.205C105.407 119.205 105.406 119.204 105.404 119.203L105.399 119.2L105.396 119.198L105.394 119.197Z"
          fill="#0D0B6D"
        />
        <path
          d="M282.599 71.5359L257.014 102.352C256.62 102.827 256.28 103.343 255.998 103.891L251.541 112.573L256.892 117.016L264.607 111.038C265.094 110.661 265.538 110.231 265.932 109.757L291.517 78.9401C293.008 77.145 292.761 74.4816 290.966 72.9912L288.548 70.9842C286.753 69.4938 284.09 69.7408 282.599 71.5359Z"
          fill="#0D0B6D"
        />
        <path
          d="M283.597 75.7787C283.969 75.3299 284.635 75.2682 285.084 75.6408L287.026 77.2532C287.475 77.6257 287.536 78.2916 287.164 78.7404L279.628 87.8169C279.256 88.2657 278.59 88.3274 278.141 87.9548L276.199 86.3425C275.75 85.9699 275.688 85.304 276.061 84.8552L283.597 75.7787Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M259.85 118.468C259.851 118.469 259.851 118.47 258.023 119.67C256.194 120.87 256.195 120.87 256.195 120.871L256.196 120.873L256.197 120.876L256.199 120.88C256.2 120.881 256.2 120.881 256.2 120.881C256.2 120.881 256.2 120.881 256.2 120.88L256.185 120.853C256.169 120.823 256.14 120.77 256.098 120.697C256.014 120.551 255.878 120.327 255.691 120.051C255.315 119.498 254.743 118.755 253.978 118.021C252.455 116.564 250.215 115.182 247.172 115.307L247 110.464C251.345 110.285 254.573 112.296 256.672 114.306C257.717 115.307 258.494 116.317 259.015 117.083C259.276 117.467 259.475 117.795 259.614 118.035C259.683 118.155 259.737 118.254 259.777 118.327C259.797 118.364 259.813 118.395 259.825 118.419L259.841 118.45L259.847 118.461L259.849 118.466L259.85 118.468Z"
          fill="#0D0B6D"
        />
      </g>
    </svg>
  );
};

export default SvgPeople;
