import { MapDispatchToProps, connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import React, { FC, useState, useEffect } from "react";

import { ReactComponent as SubmitFail } from "./images/submit-fail.svg";
import { resetForm } from "reducers/formReducer";
import { Routes } from "common/routes";
import Button, {
  ButtonAppearance,
  ButtonFontSize,
} from "shared-components/Button";
import ContainerMobile from "shared-components/ContainerMobile";
import ScreenFull from "shared-components/ScreenFull";
import Transition from "shared-components/Transition";

interface DispatchProps {
  resetForm: typeof resetForm;
}

const FormFailurePage: FC<DispatchProps> = ({ resetForm }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { replace, push } = useHistory();
  const { search } = useLocation();

  useEffect(() => {
    resetForm();
    const frame = requestAnimationFrame(() => {
      setIsLoading(true);
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [resetForm]);

  const handleSelfClick = () => {
    replace(Routes.FORM_SELF);
  };

  const handleReportClick = () => {
    push({ pathname: Routes.FORM_REPORT_ERROR, search });
  };

  return (
    <ScreenFull data-testid="form-failure-page">
      <ContainerMobile className="min-h-full flex flex-col items-center justify-center p-4">
        <Transition isActive={isLoading} type="fly" duration={600}>
          <h2 className="text-center mb-4">Oh no!</h2>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600}>
          <p className="text-center mb-2">
            Something went wrong.
            <br />
            Your declaration is unsuccessful.
            <br />
            Please try again.
          </p>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={200}>
          <SubmitFail className="mb-4" />
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={400}>
          <div className="w-full">
            <Button
              appearance={ButtonAppearance.PILL_SOLID}
              onClick={handleSelfClick}
            >
              Try again
            </Button>
            <Button
              appearance={ButtonAppearance.TEXT_BLUE}
              size={ButtonFontSize.SMALL}
              onClick={handleReportClick}
              className="mx-auto py-4"
            >
              Report an error
            </Button>
          </div>
        </Transition>
      </ContainerMobile>
    </ScreenFull>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  resetForm,
};

export default connect(null, mapDispatchToProps)(FormFailurePage);
