import React, { FC } from "react";

import styles from "./style.module.scss";

interface Props {
  [rest: string]: any;
}

const SvgSun: FC<Props> = (rest) => {
  return (
    <svg
      data-testid="svg-sun"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <line
        className={styles.ray}
        x1="11.1314"
        y1="24"
        x2="15.6569"
        y2="28.5255"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        className={styles.rayLong}
        x1="15.0928"
        y1="14.2928"
        x2="20.7928"
        y2="24.1655"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        className={styles.ray}
        x1="25.9798"
        y1="15.5657"
        x2="27.6362"
        y2="21.7476"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        className={styles.rayLong}
        x1="35.8"
        y1="9.8"
        x2="35.8"
        y2="21.2"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        className={styles.ray}
        x1="0.8"
        y1="-0.8"
        x2="7.2"
        y2="-0.8"
        transform="matrix(-0.258819 0.965926 0.965926 0.258819 46.0706 15)"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        className={styles.rayLong}
        x1="0.8"
        y1="-0.8"
        x2="12.2"
        y2="-0.8"
        transform="matrix(-0.5 0.866025 0.866025 0.5 56.5 14)"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        className={styles.ray}
        x1="59.5255"
        y1="24"
        x2="55"
        y2="28.5255"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60 49.2949C60 35.8772 49.1228 25 35.7051 25C24.1237 25 14.435 33.1037 12 43.95"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="4.8"
        y1="50.2"
        x2="67.2"
        y2="50.2"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="12.8"
        y1="58.2"
        x2="59.2"
        y2="58.2"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="24.8"
        y1="66.2"
        x2="47.2"
        y2="66.2"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgSun;
