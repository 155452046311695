import React, { ReactNode } from "react";

import {
  FormActions,
  UPDATE_FORM_DATA,
  UPDATE_FORM_ERRORS,
  RESET_FORM,
} from "./actions";
import { Routes } from "common/routes";

export interface FormData {
  isTemperatureAboveThreshold?: boolean;
  hasSymptoms?: boolean;
  isInOffice?: boolean;
  hasTravelledToAffectedCountries?: boolean;
  hasQuarantineOrder?: boolean;
  hasContactWithCases?: boolean;
  remarks?: string;
}

export interface FormErrors {
  isTemperatureAboveThreshold?: string;
  hasSymptoms?: string;
  isInOffice?: string;
  hasTravelledToAffectedCountries?: string;
  hasQuarantineOrder?: string;
  hasContactWithCases?: string;
}

interface RadioOption {
  label: string;
  labelTwo?: string;
  value: any;
}

interface Question {
  questionV1?: ReactNode;
  questionV2: string;
  options: RadioOption[];
}

export interface StaticData {
  isTemperatureAboveThreshold: RadioOption[];
  hasSymptoms: Question;
  isInOffice: Question;
  hasTravelledToAffectedCountries: Question;
  hasQuarantineOrder: Question;
  hasContactWithCases: Question;
}

export interface FormState {
  formData: FormData;
  formErrors: FormErrors;
  staticData: StaticData;
}

export const initialState: FormState = {
  formData: {
    isTemperatureAboveThreshold: undefined,
    hasSymptoms: undefined,
    isInOffice: undefined,
    hasTravelledToAffectedCountries: undefined,
    hasQuarantineOrder: undefined,
    hasContactWithCases: undefined,
    remarks: "",
  },
  formErrors: {
    isTemperatureAboveThreshold: undefined,
    hasSymptoms: undefined,
    isInOffice: undefined,
    hasTravelledToAffectedCountries: undefined,
    hasQuarantineOrder: undefined,
    hasContactWithCases: undefined,
  },
  staticData: {
    isTemperatureAboveThreshold: [
      { label: "Below", labelTwo: "37.5°C", value: false },
      { label: "37.5°C", labelTwo: "& above", value: true },
    ],
    hasSymptoms: {
      questionV1:
        "Do you have respiratory symptoms like cough, sore throat, runny nose or shortness of breath?",
      questionV2:
        "Do you have respiratory symptoms like cough, sore throat, runny nose, shortness of breath or been issued medical certificate for respiratory symptoms?",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    isInOffice: {
      questionV1: undefined,
      questionV2:
        "Are you physically present at any workplace (office/on-site)?",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    hasTravelledToAffectedCountries: {
      questionV1: (
        <>
          Have you travelled to any{" "}
          <a
            href={Routes.INFOGRAPHICS_CNA}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-web-blue"
          >
            COVID-19 affected countries
          </a>{" "}
          in the past 14 days?
        </>
      ),
      questionV2: "Have you travelled overseas in the past 14 days?",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    hasQuarantineOrder: {
      questionV1: undefined,
      questionV2: "Are you currently on Quarantine Order/Stay-Home Notice?",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
    hasContactWithCases: {
      questionV1:
        "To your knowledge, have you come into contact with any confirmed or suspected COVID-19 cases?",
      questionV2:
        "To your knowledge, have you come into close contact with any confirmed or suspected COVID-19 cases in the past 14 days?",
      options: [
        { label: "Yes", value: true },
        { label: "No", value: false },
      ],
    },
  },
};

export const formReducer = (
  state = initialState,
  action: FormActions
): FormState => {
  switch (action.type) {
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.payload,
        },
      };

    case UPDATE_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          ...action.payload,
        },
      };

    case RESET_FORM:
      return initialState;

    default:
      return state;
  }
};
