import classnames from "classnames";
import React, { FC, ReactNode, useEffect, CSSProperties } from "react";

import styles from "./style.module.scss";

interface Props {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  [rest: string]: any;
}

const ScreenFull: FC<Props> = ({ children, className, style, ...rest }) => {
  const setDocHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  useEffect(() => {
    setDocHeight();
    window.addEventListener("resize", setDocHeight);
  }, []);

  return (
    <div
      className={classnames(styles.screenFull, className)}
      style={style}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ScreenFull;
