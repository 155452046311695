import React, { FC, ReactNode } from "react";

import { ReactComponent as ErrorIcon } from "./images/error-icon.svg";

interface Props {
  children: ReactNode;
}

const FieldError: FC<Props> = ({ children }) => {
  return (
    <div className="flex items-center text-web-red">
      <ErrorIcon className="inline m-1" />
      <span className="text-xs">{children}</span>
    </div>
  );
};

export default FieldError;
