import { Switch, Route } from "react-router-dom";
import React, { FC } from "react";

import { Routes } from "common/routes";
import ConnectionLostPage from "pages/ConnectionLostPage";
import DeclarationHistoryPage from "pages/DeclarationHistoryPage";
import FormFailurePage from "pages/FormFailurePage";
import FormSelfMorePage from "pages/FormSelfMorePage";
import FormSelfPage from "pages/FormSelfPage";
import FormSuccessPage from "pages/FormSuccessPage";
import NotFoundPage from "pages/NotFoundPage";
import LimitExceedPage from "pages/LimitExceedPage";

import ScrollToTop from "./ScrollToTop";
import SessionExpiredPage from "pages/SessionExpiredPage";

const AppRouter: FC = () => {
  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route exact={true} path={Routes.FORM_SELF} component={FormSelfPage} />
        <Route
          exact={true}
          path={Routes.FORM_SELF_MORE}
          component={FormSelfMorePage}
        />
        <Route
          exact={true}
          path={Routes.FORM_SUCCESS}
          component={FormSuccessPage}
        />
        <Route
          exact={true}
          path={Routes.FORM_FAILURE}
          component={FormFailurePage}
        />
        <Route
          exact={true}
          path={Routes.FORM_REPORT_ERROR}
          component={FormFailurePage}
        />
        <Route
          exact={true}
          path={Routes.LIMIT_EXCEEDED}
          component={LimitExceedPage}
        />
        <Route
          exact={true}
          path={Routes.DECLARATION_HISTORY}
          component={DeclarationHistoryPage}
        />
        <Route
          exact={true}
          path={Routes.CONNECTION_LOST}
          component={ConnectionLostPage}
        />
        <Route
          exact={true}
          path={Routes.SESSION_EXPIRED}
          component={SessionExpiredPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </>
  );
};

export default AppRouter;
