import { FormData } from "./reducer";

export const UPDATE_FORM_DATA = "form/UPDATE_FORM_DATA";
export const UPDATE_FORM_ERRORS = "form/UPDATE_FORM_ERRORS";
export const RESET_FORM = "form/RESET_FORM";

type KeyValueOf<K> = {
  [P in keyof K]?: K[P];
};

interface UpdateFormData {
  type: typeof UPDATE_FORM_DATA;
  payload: KeyValueOf<FormData>;
}

interface UpdateFormErrors {
  type: typeof UPDATE_FORM_ERRORS;
  payload: { [key in keyof FormData]: string };
}

interface ResetForm {
  type: typeof RESET_FORM;
}

export type FormActions = UpdateFormData | UpdateFormErrors | ResetForm;

export const updateFormData = (obj: KeyValueOf<FormData>): FormActions => ({
  type: UPDATE_FORM_DATA,
  payload: obj,
});

export const updateFormErrors = (
  obj: { [key in keyof FormData]: string }
): FormActions => ({
  type: UPDATE_FORM_ERRORS,
  payload: obj,
});

export const resetForm = (): FormActions => ({
  type: RESET_FORM,
});
