import classnames from "classnames/bind";
import { FC, ReactNode, Children, cloneElement, ReactElement } from "react";

import styles from "./style.module.scss";

const cx = classnames.bind(styles);

interface Props {
  children: ReactNode;
  isActive: boolean;
  type: "fly" | "fade";
  duration: number;
  delay?: number;
}

const Transition: FC<Props> = ({
  children,
  isActive,
  type,
  duration,
  delay = 0,
}) => {
  const child = Children.only(children) as ReactElement;

  const transitionClass = cx({
    fly: type === "fly",
    fade: type === "fade",
    active: isActive,
  });

  const childProps = {
    className: classnames(child.props.className, transitionClass),
    style: {
      ...child.props.style,
      transitionDuration: `${duration}ms`,
      transitionDelay: `${delay}ms`,
    },
  };

  return cloneElement(child, childProps);
};

export default Transition;
