import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { refreshSessionToken } from "./tokens";

type RequestData = Record<string, unknown>;

export const refreshAndPost = async <T = any, R = AxiosResponse<T>>(
  url: string,
  data?: RequestData,
  config?: AxiosRequestConfig
): Promise<R> => {
  const token = await refreshSessionToken();
  const reqConfig: AxiosRequestConfig = { ...(config || {}) };
  if (token) {
    reqConfig.headers = {
      ...(reqConfig.headers || {}),
      session_token: token,
    };
  }
  return axios.post<T, R>(url, data, reqConfig);
};

export const refreshAndGet = async <T = any, R = AxiosResponse<T>>(
  url: string,
  config?: AxiosRequestConfig
): Promise<R> => {
  const token = await refreshSessionToken();
  const reqConfig: AxiosRequestConfig = { ...(config || {}) };
  if (token) {
    reqConfig.headers = {
      ...(reqConfig.headers || {}),
      session_token: token,
    };
  }
  return axios.get<T, R>(url, reqConfig);
};
