import { useHistory } from "react-router-dom";
import React, { FC, useState, useEffect } from "react";

import { ReactComponent as SubmitFail } from "./images/submit-fail.svg";
import { Routes } from "common/routes";
import Button, { ButtonAppearance } from "shared-components/Button";
import ContainerMobile from "shared-components/ContainerMobile";
import ScreenFull from "shared-components/ScreenFull";
import Transition from "shared-components/Transition";

const NotFoundPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { push } = useHistory();

  useEffect(() => {
    const frame = requestAnimationFrame(() => {
      setIsLoading(true);
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, []);

  const handleClick = () => {
    push(Routes.FORM_SELF);
  };

  return (
    <ScreenFull data-testid="not-found-page">
      <ContainerMobile className="min-h-full flex flex-col items-center justify-center p-4">
        <Transition isActive={isLoading} type="fly" duration={600}>
          <h2 className="text-center mb-4">Oops!</h2>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600}>
          <p className="text-center mb-2">
            The page you&apos;re looking for
            <br />
            cannot be found.
          </p>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={200}>
          <SubmitFail className="mb-4" />
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={400}>
          <div className="w-full">
            <Button
              appearance={ButtonAppearance.PILL_SOLID}
              onClick={handleClick}
            >
              Return to home
            </Button>
          </div>
        </Transition>
      </ContainerMobile>
    </ScreenFull>
  );
};

export default NotFoundPage;
