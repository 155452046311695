import React, { FC } from "react";

import styles from "./style.module.scss";

interface Props {
  [rest: string]: any;
}

const SvgMoon: FC<Props> = (rest) => {
  return (
    <svg
      data-testid="svg-moon"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        className={styles.moon}
        d="M62.3613 46.9644C59.5905 47.9472 56.6077 48.4822 53.5 48.4822C38.8645 48.4822 27 36.6177 27 21.9822C27 16.422 28.7124 11.2617 31.6387 7C21.3608 10.6458 14 20.4544 14 31.9822C14 46.6177 25.8645 58.4822 40.5 58.4822C49.5753 58.4822 57.5851 53.9202 62.3613 46.9644Z"
        stroke="#133D99"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g className={styles.starOne}>
        <path
          d="M4 60.2H10.4"
          stroke="#133D99"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.20001 63.4L7.20001 57"
          stroke="#133D99"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <g className={styles.starTwo}>
        <path
          d="M58 23H68"
          stroke="#133D99"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M63 28L63 18"
          stroke="#133D99"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>

      <g className={styles.starThree}>
        <path
          d="M44 11.2H50.4"
          stroke="#133D99"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.2 14.4L47.2 7.99999"
          stroke="#133D99"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default SvgMoon;
