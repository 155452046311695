import { connect, MapDispatchToProps } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { FC, useState, useEffect } from "react";

import { ReactComponent as ConnectionLost } from "./images/connection-lost.svg";
import { resetForm } from "reducers/formReducer";
import { Routes } from "common/routes";
import Button, { ButtonAppearance } from "shared-components/Button";
import ContainerMobile from "shared-components/ContainerMobile";
import ScreenFull from "shared-components/ScreenFull";
import Transition from "shared-components/Transition";

interface DispatchProps {
  resetForm: typeof resetForm;
}

const ConnectionLostPage: FC<DispatchProps> = ({ resetForm }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { replace } = useHistory();

  useEffect(() => {
    resetForm();
    const frame = requestAnimationFrame(() => {
      setIsLoading(true);
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [resetForm]);

  const handleSelfClick = () => {
    replace(Routes.FORM_SELF);
  };

  return (
    <ScreenFull data-testid="connection-lost-page">
      <ContainerMobile className="min-h-full flex flex-col items-center justify-center p-4">
        <Transition isActive={isLoading} type="fly" duration={600}>
          <h2 className="text-center mb-4">Connection lost</h2>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600}>
          <p className="text-center mb-2">
            Something went wrong with
            <br />
            the internet connection.
            <br />
            Please try again.
          </p>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={200}>
          <ConnectionLost className="mb-4" />
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={400}>
          <div className="w-full">
            <Button
              appearance={ButtonAppearance.PILL_SOLID}
              onClick={handleSelfClick}
            >
              Try again
            </Button>
          </div>
        </Transition>
      </ContainerMobile>
    </ScreenFull>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, unknown> = {
  resetForm,
};

export default connect(null, mapDispatchToProps)(ConnectionLostPage);
