import { MapStateToProps, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import React, { FC, useEffect, useState } from "react";

import { formatDateTime } from "common/utils";
import { RootState } from "reducers";
import { Routes } from "common/routes";
import { StaticData } from "reducers/formReducer";
import Button, {
  ButtonAppearance,
  ButtonFontSize,
} from "shared-components/Button";
import Collapsible from "shared-components/Collapsible";
import CollapsiblePanel from "shared-components/CollapsiblePanel";
import ContainerMobile from "shared-components/ContainerMobile";
import DeclarationHistoryQuestionGroup from "components/DeclarationHistoryQuestionGroup";
import ScreenFull from "shared-components/ScreenFull";
import SvgSpinner from "components/SvgSpinner";
import { refreshAndGet } from "../../utils/api";

interface StateProps {
  staticData: StaticData;
}

interface DeclarationData {
  submittedAt: string;
  version: number;
  isTemperatureAboveThreshold: boolean;
  declarationFields: {
    hasSymptoms: boolean;
    isInOffice?: boolean;
    hasTravelledToAffectedCountries?: boolean;
    hasQuarantineOrder?: boolean;
    hasContactWithCases?: boolean;
    remarks: string;
  };
}

type Props = StateProps;

const DeclarationHistoryPage: FC<Props> = ({ staticData }) => {
  const [declarationData, setDeclarationData] = useState<DeclarationData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { replace, push } = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint =
          process.env.REACT_APP_TGW_API_ENDPOINT ||
          "https://temperature.api.muskgowhere.com";

        /*
        // Replaced in DWP-4559, enforce refreshig of access token before calling backend
        const response = await axios.get(endpoint + "/declarations", {
          withCredentials: true,
        });
        */
        const response = await refreshAndGet(`${endpoint}/declarations`, {
          withCredentials: true,
        });

        setDeclarationData(response.data);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);

        if (!err.response) {
          replace(Routes.CONNECTION_LOST);
          return;
        }

        if (err.response?.status === 401) {
          replace(Routes.SESSION_EXPIRED);
          return;
        }

        replace({
          pathname: Routes.FORM_FAILURE,
          search: queryString.stringify(err.response.data),
        });
      }
    };

    fetchData();
  }, [replace]);

  const handleClick = () => {
    push(Routes.FORM_SELF);
  };

  const renderCollapsible = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center">
          <SvgSpinner width="32" height="32" />
        </div>
      );
    }

    return (
      <>
        <p className="font-bold border-b px-4 pb-2">Last 28 Days</p>

        {declarationData.map((item, index) => {
          const {
            hasSymptoms,
            isInOffice,
            hasTravelledToAffectedCountries,
            hasQuarantineOrder,
            hasContactWithCases,
            remarks,
          } = item.declarationFields;

          return (
            <Collapsible key={`collapsible_${index}`}>
              <CollapsiblePanel
                title={
                  <>
                    <p className="text-xs text-left mb-2">
                      {formatDateTime(item.submittedAt)}
                    </p>

                    <p className="font-bold text-left">
                      {item.version > 2
                        ? item.isTemperatureAboveThreshold
                          ? "37.5°C & Above"
                          : "Below 37.5°C"
                        : item.isTemperatureAboveThreshold
                        ? "38°C & Above"
                        : "Below 38°C"}
                    </p>
                  </>
                }
              >
                <DeclarationHistoryQuestionGroup
                  question={
                    item.version === 1
                      ? staticData.hasSymptoms.questionV1
                      : staticData.hasSymptoms.questionV2
                  }
                  answer={hasSymptoms}
                />

                {isInOffice !== undefined && (
                  <DeclarationHistoryQuestionGroup
                    question={staticData.isInOffice.questionV2}
                    answer={isInOffice}
                  />
                )}

                {hasTravelledToAffectedCountries !== undefined && (
                  <DeclarationHistoryQuestionGroup
                    question={
                      item.version === 1
                        ? staticData.hasTravelledToAffectedCountries.questionV1
                        : staticData.hasTravelledToAffectedCountries.questionV2
                    }
                    answer={hasTravelledToAffectedCountries}
                  />
                )}

                {hasQuarantineOrder !== undefined && (
                  <DeclarationHistoryQuestionGroup
                    question={staticData.hasQuarantineOrder.questionV2}
                    answer={hasQuarantineOrder}
                  />
                )}

                {hasContactWithCases !== undefined && (
                  <DeclarationHistoryQuestionGroup
                    question={
                      item.version === 1
                        ? staticData.hasContactWithCases.questionV1
                        : staticData.hasContactWithCases.questionV2
                    }
                    answer={hasContactWithCases}
                  />
                )}

                {remarks !== "" && (
                  <DeclarationHistoryQuestionGroup
                    question="Remarks"
                    answer={remarks}
                  />
                )}
              </CollapsiblePanel>
            </Collapsible>
          );
        })}
      </>
    );
  };

  return (
    <ScreenFull data-testid="declaration-history-page">
      <ContainerMobile>
        <div className="p-4 pb-6">
          <Button
            align="left"
            appearance={ButtonAppearance.TEXT_BLUE}
            size={ButtonFontSize.SMALL}
            onClick={handleClick}
          >
            Return to Temperature
          </Button>
        </div>
        {renderCollapsible()}
      </ContainerMobile>
    </ScreenFull>
  );
};

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = (
  state
) => {
  return {
    staticData: state.form.staticData,
  };
};

export default connect(mapStateToProps)(DeclarationHistoryPage);
