import React, { FC } from "react";

import styles from "./style.module.scss";

interface Props {
  [rest: string]: any;
}

const SvgCoffee: FC<Props> = (rest) => {
  return (
    <svg
      data-testid="svg-coffee"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4236 20.9352C21.53 20.706 21.7598 20.5593 22.0125 20.5593H50.951C51.2037 20.5593 51.4335 20.706 51.5399 20.9352L53.9514 26.1293C54.0448 26.3303 54.0291 26.5651 53.9098 26.752C53.7906 26.9388 53.5842 27.0519 53.3625 27.0519H19.6009C19.3792 27.0519 19.1729 26.9388 19.0536 26.752C18.9344 26.5651 18.9187 26.3303 19.012 26.1293L21.4236 20.9352ZM22.4269 21.8578L20.6182 25.7534H52.3453L50.5366 21.8578H22.4269Z"
        fill="#133D99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4035 56.7757C22.4411 57.1035 22.7186 57.351 23.0485 57.351H49.9152C50.2452 57.351 50.5227 57.1035 50.5603 56.7757L53.1421 34.2679C53.1631 34.0841 53.1048 33.8999 52.9816 33.7618C52.8584 33.6237 52.6821 33.5447 52.497 33.5447H20.4668C20.2817 33.5447 20.1054 33.6237 19.9822 33.7618C19.859 33.8999 19.8006 34.0841 19.8217 34.2679L22.4035 56.7757ZM23.6276 56.0524L21.1948 34.8432H51.769L49.3362 56.0524H23.6276Z"
        fill="#133D99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6071 34.0608L20.9906 26.5663C20.9807 26.4458 21.0217 26.3266 21.1037 26.2376C21.1856 26.1486 21.3011 26.098 21.422 26.098H51.5416C51.6626 26.098 51.778 26.1486 51.86 26.2376C51.942 26.3266 51.9829 26.4458 51.973 26.5663L51.3566 34.0608H50.488L51.0717 26.9637H21.8919L22.4757 34.0608H21.6071Z"
        fill="#133D99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9445 26.091C21.0674 25.9575 21.2406 25.8816 21.422 25.8816H51.5416C51.7231 25.8816 51.8963 25.9575 52.0192 26.091C52.1421 26.2244 52.2036 26.4032 52.1887 26.5841L51.5723 34.0785C51.5631 34.1908 51.4693 34.2772 51.3566 34.2772H50.488C50.4275 34.2772 50.3698 34.2519 50.3288 34.2074C50.2878 34.1629 50.2673 34.1033 50.2723 34.043L50.8368 27.1801H22.1269L22.6914 34.043C22.6963 34.1033 22.6758 34.1629 22.6349 34.2074C22.5939 34.2519 22.5362 34.2772 22.4757 34.2772H21.6071C21.4944 34.2772 21.4006 34.1908 21.3914 34.0785L20.775 26.5841L20.9906 26.5663M21.422 26.3144C21.3616 26.3144 21.3038 26.3397 21.2629 26.3842C21.2219 26.4287 21.2014 26.4883 21.2063 26.5486L21.8064 33.8444H22.2407L21.6763 26.9814C21.6713 26.9212 21.6918 26.8616 21.7328 26.8171C21.7737 26.7726 21.8315 26.7473 21.8919 26.7473H51.0717C51.1322 26.7473 51.1899 26.7726 51.2309 26.8171C51.2719 26.8616 51.2924 26.9212 51.2874 26.9814L50.7229 33.8444H51.1573L51.7573 26.5486C51.7623 26.4883 51.7418 26.4287 51.7008 26.3842C51.6599 26.3397 51.6021 26.3144 51.5416 26.3144H21.422Z"
        fill="#133D99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1616 56.6504L25.0446 67.3862C25.0631 67.6108 25.2507 67.7836 25.476 67.7836H47.5562C47.7815 67.7836 47.9691 67.6108 47.9876 67.3862L48.8706 56.6504H48.002L47.1575 66.9179H25.8747L25.0302 56.6504H24.1616Z"
        fill="#133D99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.0024 56.5038C24.0434 56.4593 24.1011 56.434 24.1616 56.434H25.0302C25.1429 56.434 25.2367 56.5204 25.2459 56.6326L26.0741 66.7015H46.9581L47.7863 56.6326C47.7955 56.5204 47.8894 56.434 48.002 56.434H48.8706C48.9311 56.434 48.9888 56.4593 49.0298 56.5038C49.0708 56.5482 49.0913 56.6078 49.0863 56.6681L48.2033 67.404C48.1756 67.7408 47.8941 68 47.5562 68H25.476C25.1381 68 24.8567 67.7408 24.829 67.404L23.9459 56.6681C23.941 56.6078 23.9615 56.5482 24.0024 56.5038ZM24.3966 56.8668L25.2603 67.3685C25.2696 67.4807 25.3634 67.5672 25.476 67.5672H47.5562C47.6688 67.5672 47.7626 67.4807 47.7719 67.3685L48.6357 56.8668H48.2014L47.3732 66.9356C47.3639 67.0479 47.2701 67.1343 47.1575 67.1343H25.8747C25.7621 67.1343 25.6683 67.0479 25.659 66.9356L24.8309 56.8668H24.3966Z"
        fill="#133D99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5796 21.6412C22.7027 21.7791 22.8789 21.8579 23.0638 21.8579H49.8999C50.0848 21.8579 50.2609 21.7791 50.3841 21.6412C50.5073 21.5033 50.5658 21.3194 50.5451 21.1357L50.1537 17.673C50.1166 17.3447 49.8389 17.0967 49.5085 17.0967H23.4551C23.1248 17.0967 22.8471 17.3447 22.81 17.673L22.4186 21.1357C22.3978 21.3194 22.4564 21.5033 22.5796 21.6412ZM23.7905 20.5594L24.0352 18.3952H48.9285L49.1731 20.5594H23.7905Z"
        fill="#133D99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7043 38.6416L30.1049 44.241C29.6404 44.7056 29.6404 45.4587 30.1049 45.9233L35.7043 51.5227C36.1689 51.9872 36.922 51.9872 37.3866 51.5227L42.986 45.9233C43.4505 45.4587 43.4505 44.7056 42.986 44.241L37.3866 38.6416C36.922 38.1771 36.1689 38.1771 35.7043 38.6416ZM29.2638 43.3999C28.3347 44.329 28.3347 45.8353 29.2638 46.7644L34.8632 52.3638C35.7923 53.2929 37.2986 53.2929 38.2277 52.3638L43.8271 46.7644C44.7562 45.8353 44.7562 44.329 43.8271 43.3999L38.2277 37.8005C37.2986 36.8714 35.7923 36.8714 34.8632 37.8005L29.2638 43.3999Z"
        fill="#133D99"
      />
      <path
        className={styles.smokeOne}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9409 4.19356C29.1671 3.93548 29.5339 3.93548 29.7602 4.19356L31.305 5.95588C32.3845 7.18726 32.3845 9.18372 31.305 10.4151C30.6781 11.1303 30.6781 12.2899 31.305 13.0051L32.8499 14.7674C33.0762 15.0255 33.0762 15.444 32.8499 15.702C32.6237 15.9601 32.2569 15.9601 32.0306 15.702L30.4857 13.9397C29.4063 12.7083 29.4063 10.7119 30.4857 9.4805C31.1127 8.76529 31.1127 7.6057 30.4857 6.89048L28.9409 5.12817C28.7146 4.87009 28.7146 4.45165 28.9409 4.19356Z"
        fill="#133D99"
      />
      <path
        className={styles.smokeTwo}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.223 4.19356C33.4945 3.93548 33.9346 3.93548 34.2061 4.19356L36.06 5.95588C37.3553 7.18726 37.3553 9.18372 36.06 10.4151C35.3076 11.1303 35.3076 12.2899 36.06 13.0051L37.9138 14.7674C38.1853 15.0255 38.1853 15.444 37.9138 15.702C37.6424 15.9601 37.2022 15.9601 36.9307 15.702L35.0768 13.9397C33.7815 12.7083 33.7815 10.7119 35.0768 9.4805C35.8292 8.76529 35.8292 7.6057 35.0768 6.89048L33.223 5.12817C32.9515 4.87009 32.9515 4.45165 33.223 4.19356Z"
        fill="#133D99"
      />
      <path
        className={styles.smokeThree}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4714 4.19356C37.7429 3.93548 38.183 3.93548 38.4545 4.19356L40.3084 5.95588C41.6038 7.18726 41.6037 9.18372 40.3084 10.4151C39.556 11.1303 39.556 12.2899 40.3084 13.0051L42.1623 14.7674C42.4338 15.0255 42.4338 15.444 42.1623 15.702C41.8908 15.9601 41.4506 15.9601 41.1791 15.702L39.3252 13.9397C38.0299 12.7083 38.0299 10.7119 39.3252 9.4805C40.0776 8.76529 40.0776 7.6057 39.3252 6.89048L37.4714 5.12817C37.1999 4.87009 37.1999 4.45165 37.4714 4.19356Z"
        fill="#133D99"
      />
    </svg>
  );
};

export default SvgCoffee;
