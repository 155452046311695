import classnames from "classnames";
import React, { FC, ReactNode } from "react";

import { ReactComponent as ErrorIcon } from "./images/error-icon.svg";
import ContainerMobile from "shared-components/ContainerMobile";

export enum AlertAppearance {
  ERROR = "error",
}

interface Props {
  appearance: AlertAppearance;
  children: ReactNode;
}

const Alert: FC<Props> = ({ appearance, children }) => {
  const appearanceClass = {
    "bg-web-redlight text-web-red": appearance === AlertAppearance.ERROR,
  };

  return (
    <div className={classnames("w-full flex items-center", appearanceClass)}>
      <ContainerMobile className="px-4">
        <ErrorIcon className="inline m-1" />
        <span className="text-xs">{children}</span>
      </ContainerMobile>
    </div>
  );
};

export default Alert;
