import { MapDispatchToProps, connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import React, { FC, useState, useEffect } from "react";

import { ReactComponent as SubmitNay } from "./images/submit-nay.svg";
import { ReactComponent as SubmitYay } from "./images/submit-yay.svg";
import { resetForm } from "reducers/formReducer";
import { Routes } from "common/routes";
import Button, {
  ButtonAppearance,
  ButtonFontSize,
} from "shared-components/Button";
import ContainerMobile from "shared-components/ContainerMobile";
import ScreenFull from "shared-components/ScreenFull";
import Transition from "shared-components/Transition";

interface DispatchProps {
  resetForm: typeof resetForm;
}

const FormSuccessPage: FC<DispatchProps> = ({ resetForm }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { replace } = useHistory();
  const { search } = useLocation();
  const query = queryString.parse(search);

  useEffect(() => {
    resetForm();
    const frame = requestAnimationFrame(() => {
      setIsLoading(true);
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [resetForm]);

  const handleHistoryClick = () => {
    replace(Routes.DECLARATION_HISTORY);
  };

  const handleSelfClick = () => {
    replace(Routes.FORM_SELF);
  };

  const renderText = () => {
    if (query.unwell === "true") {
      return (
        <p className="text-center mb-2">
          If feeling unwell, please stay home
          <br />
          and seek medical attention promptly.
        </p>
      );
    } else {
      return (
        <p className="text-center mb-2">
          Continue to keep up with good
          <br />
          personal hygiene and stay healthy!
        </p>
      );
    }
  };

  return (
    <ScreenFull data-testid="form-success-page">
      <ContainerMobile className="min-h-full flex flex-col items-center justify-center p-4">
        <Transition isActive={isLoading} type="fly" duration={600}>
          <h2 className="text-center mb-4">
            Declaration
            <br />
            has been submitted!
          </h2>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600}>
          {renderText()}
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={200}>
          {query.unwell === "true" ? (
            <SubmitNay className="mb-4" />
          ) : (
            <SubmitYay className="mb-4" />
          )}
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={400}>
          <div className="w-full">
            <Button
              appearance={ButtonAppearance.PILL_GHOST}
              onClick={handleHistoryClick}
            >
              View History
            </Button>
            <Button
              appearance={ButtonAppearance.TEXT_BLUE}
              size={ButtonFontSize.SMALL}
              onClick={handleSelfClick}
              className="mx-auto py-4"
            >
              Back to Temperature Declaration
            </Button>
          </div>
        </Transition>
      </ContainerMobile>
    </ScreenFull>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, unknown> = {
  resetForm,
};

export default connect(null, mapDispatchToProps)(FormSuccessPage);
