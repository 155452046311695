import classnames from "classnames/bind";
import React, { FC, ChangeEventHandler } from "react";

import FieldError from "shared-components/FieldError";
import styles from "./style.module.scss";

const cx = classnames.bind(styles);

interface Option {
  label: string;
  value: any;
}

interface Props {
  name: string;
  value: any;
  options: Option[];
  onChange: ChangeEventHandler;
  disabled?: boolean;
  error?: string;
}

const FieldRadioGroup: FC<Props> = ({
  name,
  value,
  options,
  onChange,
  disabled,
  error,
}) => {
  const renderOptions = () => {
    return options.map((option, index) => {
      const identifier = `${name}_${index}`;
      return (
        <label htmlFor={identifier} key={identifier} data-testid="radio-label">
          <input
            data-testid="radio-input"
            type="radio"
            id={identifier}
            name={name}
            className={styles.radio}
            value={option.value}
            checked={option.value === value}
            disabled={disabled}
            onChange={onChange}
          />
          <span>{option.label}</span>
        </label>
      );
    });
  };

  const radioGroupClass = cx({
    radioGroup: true,
    error,
  });

  return (
    <div className={radioGroupClass}>
      {renderOptions()}
      {error && <FieldError>{error}</FieldError>}
    </div>
  );
};

export default FieldRadioGroup;
