import React, { ReactNode, FC } from "react";

interface Props {
  question: ReactNode;
  answer: string | boolean;
}

const DeclarationHistoryQuestionGroup: FC<Props> = ({ question, answer }) => {
  const renderAnswerDisplay = () => {
    if (typeof answer === "string") {
      return answer;
    }

    return answer ? "Yes" : "No";
  };

  return (
    <div className="mt-4">
      <p className="text-xs mb-2">{question}</p>
      <p className="text-xs font-bold break-all">{renderAnswerDisplay()}</p>
    </div>
  );
};

export default DeclarationHistoryQuestionGroup;
