import React, { FC, ChangeEventHandler } from "react";

import { ReactComponent as Exclamation } from "./images/exclamation.svg";
import { ReactComponent as Heart } from "./images/heart.svg";
import FieldError from "shared-components/FieldError";
import styles from "./style.module.scss";

interface Option {
  label: string;
  labelTwo?: string;
  value: any;
}

interface Props {
  name: string;
  value: any;
  optionOne: Option;
  optionTwo: Option;
  onChange: ChangeEventHandler;
  error?: string;
}

const FormSelfTemperature: FC<Props> = ({
  name,
  value,
  optionOne,
  optionTwo,
  onChange,
  error,
}) => {
  return (
    <>
      <div className={styles.radioIcons}>
        <label
          data-testid="tempradio-label-one"
          htmlFor={`${name}_0`}
          className={styles.heartRadio}
        >
          <input
            data-testid="tempradio-input-one"
            type="radio"
            id={`${name}_0`}
            name={name}
            className={styles.radio}
            value={optionOne.value}
            checked={optionOne.value === value}
            onChange={onChange}
          />
          <div className={styles.circle}>
            <Heart />
          </div>
          <p data-testid="tempradio-text-one" className={styles.text}>
            {optionOne.label}
            <br />
            {optionOne.labelTwo && optionOne.labelTwo}
          </p>
        </label>

        <label
          data-testid="tempradio-label-two"
          htmlFor={`${name}_1`}
          className={styles.exclamRadio}
        >
          <input
            data-testid="tempradio-input-two"
            type="radio"
            id={`${name}_1`}
            name={name}
            className={styles.radio}
            value={optionTwo.value}
            checked={optionTwo.value === value}
            onChange={onChange}
          />
          <div className={styles.circle}>
            <Exclamation />
          </div>
          <p data-testid="tempradio-text-two" className={styles.text}>
            {optionTwo.label}
            <br />
            {optionTwo.labelTwo && optionTwo.labelTwo}
          </p>
        </label>
      </div>
      {error && <FieldError>{error}</FieldError>}
    </>
  );
};

export default FormSelfTemperature;
