import React, { FC, useState, useEffect } from "react";

import { ReactComponent as SubmitFail } from "./images/submit-fail.svg";
import ContainerMobile from "shared-components/ContainerMobile";
import ScreenFull from "shared-components/ScreenFull";
import Transition from "shared-components/Transition";

const SessionExpiredPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const frame = requestAnimationFrame(() => {
      setIsLoading(true);
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, []);

  return (
    <ScreenFull data-testid="form-failure-page">
      <ContainerMobile className="min-h-full flex flex-col items-center justify-center p-4">
        <Transition isActive={isLoading} type="fly" duration={600}>
          <h2 className="text-center mb-4">Session expired.</h2>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600}>
          <p className="text-center mb-2">
            Your session has expired.
            <br />
            Please quit the app and login again.
          </p>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={200}>
          <SubmitFail className="mb-4" />
        </Transition>
      </ContainerMobile>
    </ScreenFull>
  );
};

export default SessionExpiredPage;
