const allowLogging = (): boolean =>
  process.env.REACT_APP_ALLOW_LOGGING === "true";

export class Logger {
  private static _instance: Logger;

  static getInstance(): Logger {
    if (!Logger._instance) {
      Logger._instance = new Logger();
    }
    return Logger._instance;
  }

  log(msg: string, ...args: any[]): void {
    if (!allowLogging()) {
      return;
    }
    console.log(msg, ...args);
  }

  info(msg: string, ...args: any[]): void {
    if (!allowLogging()) {
      return;
    }
    console.info(msg, ...args);
  }

  warn(msg: string, ...args: any[]): void {
    if (!allowLogging()) {
      return;
    }
    console.warn(msg, ...args);
  }

  debug(msg: string, ...args: any[]): void {
    if (!allowLogging()) {
      return;
    }
    console.debug(msg, ...args);
  }

  error(msg: string, ...args: any[]): void {
    if (!allowLogging()) {
      return;
    }
    console.error(msg, ...args);
  }
}
