import React, { FC } from "react";

import styles from "./style.module.scss";

interface Props {
  [rest: string]: any;
}

const SvgSpinner: FC<Props> = (rest) => {
  return (
    <svg
      className={styles.spinner}
      width="66px"
      height="66px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle
        className={styles.path}
        fill="none"
        strokeWidth="12"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="27"
      ></circle>
    </svg>
  );
};

export default SvgSpinner;
