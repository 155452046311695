import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import { BrowserRouter } from "react-router-dom";
import { createStore, compose } from "redux";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";

import "./styles/fontawesome.css";
import "./styles/fonts.css";
import "./styles/tailwind.css";
import { rootReducer } from "./reducers";
import * as serviceWorker from "./serviceWorker";
import AppRouter from "./routes/AppRouter";

const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(rootReducer, composeEnhancer());

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
