import { format } from "date-fns";
import React, { FC } from "react";

import SvgCoffee from "components/SvgCoffee";
import SvgMoon from "components/SvgMoon";
import SvgSun from "components/SvgSun";

interface Props {
  zonedDate: Date;
}

const FormSelfIcons: FC<Props> = ({ zonedDate }) => {
  const hours = zonedDate.getHours();
  const amOrPm = format(zonedDate, "a");
  const parsedDate = format(zonedDate, "d MMM yyyy");
  let svg;

  switch (true) {
    case hours >= 7 && hours < 12:
      svg = <SvgSun data-testid="svg-sun" className="w-full mx-auto" />;
      break;
    case hours >= 12 && hours < 17:
      svg = <SvgCoffee data-testid="svg-coffee" className="w-full mx-auto" />;
      break;
    default:
      svg = <SvgMoon data-testid="svg-moon" className="w-full mx-auto" />;
  }

  return (
    <>
      {svg}
      <p className="text-center text-web-bluedark text-sm">
        {amOrPm} Declaration
      </p>
      <p className="text-center text-web-bluedark text-sm">{parsedDate}</p>
    </>
  );
};

export default FormSelfIcons;
