import { JSBridge } from "./jsbridge";
import { Logger } from "./logger";
import * as requestCodes from "../common/requestCodes";

export const refreshSessionToken = async (): Promise<string | undefined> => {
  Logger.getInstance().debug("Fetching access token from host app...");
  const token = await JSBridge.getInstance().fetchSessionToken();
  if (token) {
    Logger.getInstance().debug("Received access token from host app", token);
  } else {
    Logger.getInstance().warn("Undefined access token from host app", token);
  }
  return token;
};

/**
 * Function called by DWPInterfaceCallback function interfaceCallback.js
 * DWPInterfaceCallback function is called by host app.
 * @param args
 */
const callbackListener: InterfaceCallbackListener = (...args): void => {
  JSBridge.getInstance().callbackHandler(...args);
};

/**
 * Call this function to initiate bootstrap with JS bridge.
 * Must call this function or else bridging functions won't work.
 */
export const jsBridgeBootstrap = (): void => {
  if (
    window.setInterfaceCallbackListener &&
    typeof window.setInterfaceCallbackListener === "function"
  ) {
    window.setInterfaceCallbackListener(callbackListener);
  }

  if (window.onPageLoaded && typeof window.onPageLoaded === "function") {
    window.onPageLoaded(requestCodes.PAGE_LOADED);
  }
};
