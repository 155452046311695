import classnames from "classnames";
import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
}

const ContainerMobile: FC<Props> = ({ children, className }) => {
  return (
    <div
      data-testid="container-mobile"
      className={classnames("container mx-auto", className)}
      style={{ maxWidth: "640px" }}
    >
      {children}
    </div>
  );
};

export default ContainerMobile;
