import React, { ReactNode, FC } from "react";

interface Props {
  title: ReactNode;
  children: ReactNode;
  index?: number;
  isActive?: boolean;
  onClick?: (index: number) => void;
}

export const CollapsiblePanel: FC<Props> = ({
  title,
  children,
  index = -1,
  isActive = false,
  onClick,
}) => {
  const handleClick = () => {
    onClick && onClick(index);
  };

  const iconClass = isActive ? "fa-angle-up" : "fa-angle-down";
  const contentClass = isActive ? "block" : "hidden";

  return (
    <div className="border-b border-text-graylight p-4">
      <button
        className="w-full flex items-center justify-between focus:outline-none"
        onClick={handleClick}
      >
        <div>{title}</div>
        <i className={`far ${iconClass} text-web-blue text-xl`} />
      </button>
      <div className={contentClass}>{children}</div>
    </div>
  );
};

export default CollapsiblePanel;
