import classnames from "classnames/bind";
import React, { FC, MouseEventHandler, ReactNode, CSSProperties } from "react";

import styles from "./style.module.scss";

const cx = classnames.bind(styles);

export enum ButtonAppearance {
  PILL_SOLID = "pill solid",
  PILL_GHOST = "pill ghost",
  TEXT_BLUE = "text blue",
  TEXT_GRAY = "text gray",
}

export enum ButtonFontSize {
  SMALL = "small",
  NORMAL = "normal",
  LARGE = "large",
}

interface Props {
  children: ReactNode;
  appearance: ButtonAppearance;
  size?: ButtonFontSize;
  align?: "left" | "right";
  onClick?: MouseEventHandler;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

const Button: FC<Props> = ({
  children,
  align,
  appearance,
  size = ButtonFontSize.NORMAL,
  onClick,
  className,
  disabled,
  style,
}) => {
  const buttonClass = cx({
    button: true,
    "w-full font-bold text-white bg-web-blue px-8 py-3 rounded-full focus:outline-none":
      appearance === ButtonAppearance.PILL_SOLID,
    "w-full font-bold text-web-blue border border-web-blue px-8 py-3 rounded-full focus:outline-none":
      appearance === ButtonAppearance.PILL_GHOST,
    "flex items-center font-bold text-web-blue focus:outline-none":
      appearance === ButtonAppearance.TEXT_BLUE,
    "flex items-center font-bold text-web-gray focus:outline-none":
      appearance === ButtonAppearance.TEXT_GRAY,
    "text-sm": size === ButtonFontSize.SMALL,
    "text-base": size === ButtonFontSize.NORMAL,
    "text-2xl": size === ButtonFontSize.LARGE,
    "disabled:cursor-default": disabled,
  });

  return (
    <button
      className={classnames(buttonClass, className)}
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {align === "left" && (
        <i
          data-testid="angle-left"
          className="far fa-angle-left fa-2x mr-2"
        ></i>
      )}
      {children}
      {align === "right" && (
        <i
          data-testid="angle-right"
          className="far fa-angle-right fa-2x ml-2"
        ></i>
      )}
    </button>
  );
};

export default Button;
