import React, { FC, ChangeEventHandler } from "react";

interface Props {
  id: string;
  name: string;
  value: any;
  onChange: ChangeEventHandler;
  disabled?: boolean;
  placeholder?: string;
}

const FieldTextInput: FC<Props> = ({
  id,
  name,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  return (
    <input
      data-testid="text-input"
      type="text"
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onChange={onChange}
      className={`w-full py-1 border-b border-web-gray placeholder-web-gray outline-none
      transition-colors duration-300 ease-in-out focus:border-web-bluedark`}
    />
  );
};

export default FieldTextInput;
