import * as requestCodes from "../common/requestCodes";

const refreshSessionTokenTimeoutMS = process.env
  .REACT_APP_REFRESH_SESSION_TOKEN_TIMEOUT
  ? parseInt(process.env.REACT_APP_REFRESH_SESSION_TOKEN_TIMEOUT, 10)
  : 5000;

export class JSBridge {
  private static _instance: JSBridge;

  private _listeners: InterfaceCallbackListener[] = [];

  private _sessionToken: string | undefined;

  static getInstance(): JSBridge {
    if (!JSBridge._instance) {
      JSBridge._instance = new JSBridge();
    }
    return JSBridge._instance;
  }

  get listeners(): InterfaceCallbackListener[] {
    return this._listeners;
  }

  get sessionToken(): string | undefined {
    return this._sessionToken;
  }

  callbackHandler(
    requestCode: unknown,
    statusCode: unknown,
    dwpObject: Partial<DWPObject>
  ): void {
    this._listeners.forEach((listener) => {
      listener(requestCode, statusCode, dwpObject);
    });
  }

  addInterfaceCallbackListener(listener: InterfaceCallbackListener): void {
    if (this._listeners.indexOf(listener) === -1) {
      this._listeners.push(listener);
    }
  }

  removeInterfaceCallbackListener(listener: InterfaceCallbackListener): void {
    this._listeners = this._listeners.filter((item) => listener !== item);
  }

  fetchSessionToken(
    timeoutMS = refreshSessionTokenTimeoutMS
  ): Promise<string | undefined> {
    if (
      !(
        window.refreshAccessToken &&
        typeof window.refreshAccessToken === "function"
      )
    ) {
      return Promise.resolve(undefined);
    }

    const req = new Promise<string | undefined>((resolve) => {
      let timeout: NodeJS.Timeout | undefined = undefined;

      const handler: InterfaceCallbackListener = (
        requestCode,
        statusCode,
        dwpObject
      ) => {
        if (requestCode === requestCodes.REFRESH_ACCESS_TOKEN) {
          if (timeout) {
            clearTimeout(timeout);
          }
          this.removeInterfaceCallbackListener(handler);
          this._sessionToken = dwpObject.JWT;
          resolve(dwpObject.JWT);
        }
      };
      this.addInterfaceCallbackListener(handler);

      timeout = setTimeout(() => {
        this.removeInterfaceCallbackListener(handler);
        this._sessionToken = undefined;
        resolve(undefined);
      }, timeoutMS);

      window.refreshAccessToken(requestCodes.REFRESH_ACCESS_TOKEN);
    });

    return req;
  }
}
