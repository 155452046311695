import { connect, MapDispatchToProps } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { FC, useState, useEffect } from "react";

import { ReactComponent as SubmitFail } from "./images/submit-fail.svg";
import { resetForm } from "reducers/formReducer";
import { Routes } from "common/routes";
import Button, { ButtonAppearance } from "shared-components/Button";
import ContainerMobile from "shared-components/ContainerMobile";
import ScreenFull from "shared-components/ScreenFull";
import Transition from "shared-components/Transition";

interface DispatchProps {
  resetForm: typeof resetForm;
}

const LimitExceedPage: FC<DispatchProps> = ({ resetForm }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { replace } = useHistory();

  useEffect(() => {
    resetForm();
    const frame = requestAnimationFrame(() => {
      setIsLoading(true);
    });

    return () => {
      cancelAnimationFrame(frame);
    };
  }, [resetForm]);

  const handleSelfClick = () => {
    replace(Routes.FORM_SELF);
  };

  return (
    <ScreenFull data-testid="limit-exceed-page">
      <ContainerMobile className="min-h-full flex flex-col items-center justify-center p-4">
        <Transition isActive={isLoading} type="fly" duration={600}>
          <h2 className="text-center mb-4">Too many declarations today</h2>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600}>
          <p className="text-center mb-2">
            You have reached the daily limit for declarations. Please try again
            tomorrow.
            <br />
            (PS We love your commitment.)
          </p>
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={200}>
          <SubmitFail className="mb-4" />
        </Transition>

        <Transition isActive={isLoading} type="fly" duration={600} delay={400}>
          <div className="w-full">
            <Button
              appearance={ButtonAppearance.PILL_SOLID}
              onClick={handleSelfClick}
            >
              Ok
            </Button>
          </div>
        </Transition>
      </ContainerMobile>
    </ScreenFull>
  );
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, unknown> = {
  resetForm,
};

export default connect(null, mapDispatchToProps)(LimitExceedPage);
