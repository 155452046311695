import { MapStateToProps, MapDispatchToProps, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import React, { FC, useEffect, useState, ChangeEvent } from "react";

import {
  FormData,
  FormErrors,
  StaticData,
  updateFormData,
  updateFormErrors,
} from "reducers/formReducer";
import { RootState } from "reducers";
import { Routes } from "common/routes";
import Alert, { AlertAppearance } from "shared-components/Alert";
import Button, {
  ButtonAppearance,
  ButtonFontSize,
} from "shared-components/Button";
import ContainerMobile from "shared-components/ContainerMobile";
import FieldRadioGroup from "shared-components/FieldRadioGroup";
import FieldTextInput from "shared-components/FieldTextInput";
import ScreenFull from "shared-components/ScreenFull";
import SvgSpinner from "components/SvgSpinner";
import { refreshAndPost } from "../../utils/api";

interface StateProps {
  formData: FormData;
  formErrors: FormErrors;
  staticData: StaticData;
}

interface DispatchProps {
  updateFormData: typeof updateFormData;
  updateFormErrors: typeof updateFormErrors;
}

type Props = StateProps & DispatchProps;

const FormSelfMorePage: FC<Props> = ({
  formData,
  formErrors,
  staticData,
  updateFormData,
  updateFormErrors,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { goBack, replace } = useHistory();
  const isOthersRequired =
    formData.isTemperatureAboveThreshold === true ||
    formData.hasSymptoms === true ||
    formData.isInOffice === true;

  useEffect(() => {
    if (formData.isTemperatureAboveThreshold === undefined) {
      replace(Routes.FORM_SELF);
    }
  }, [formData.isTemperatureAboveThreshold, replace]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = event.target;
    let newValue: string | boolean = value;

    if (type === "radio") {
      newValue = value === "true";
    }

    const newFormErrors: FormErrors = {
      [name]: undefined,
    };
    const newFormData: FormData = {
      [name]: newValue,
    };

    if (formData.isTemperatureAboveThreshold === false) {
      if (
        (formData.isInOffice === false &&
          name === "hasSymptoms" &&
          newValue === false) ||
        (formData.hasSymptoms === false &&
          name === "isInOffice" &&
          newValue === false)
      ) {
        newFormErrors.hasTravelledToAffectedCountries = undefined;
        newFormErrors.hasQuarantineOrder = undefined;
        newFormErrors.hasContactWithCases = undefined;

        newFormData.hasTravelledToAffectedCountries = undefined;
        newFormData.hasQuarantineOrder = undefined;
        newFormData.hasContactWithCases = undefined;
        newFormData.remarks = "";
      }
    }

    updateFormErrors(newFormErrors);
    updateFormData(newFormData);
  };

  const handleBackClick = () => {
    goBack();
  };

  const handleSubmitClick = async () => {
    let hasErrors = false;

    const validationKeys: Array<keyof FormErrors> = [
      "isTemperatureAboveThreshold",
      "hasSymptoms",
      "isInOffice",
    ];

    if (isOthersRequired) {
      validationKeys.push(
        "hasTravelledToAffectedCountries",
        "hasQuarantineOrder",
        "hasContactWithCases"
      );
    }

    validationKeys.forEach((key) => {
      if ((formData as any)[key] === undefined) {
        updateFormErrors({ [key]: "Please select at least one option" });
        hasErrors = true;
        window.scrollTo(0, 0);
      }
    });

    if (hasErrors || isSubmitting) {
      return;
    }

    try {
      const {
        isTemperatureAboveThreshold,
        hasSymptoms,
        isInOffice,
        hasTravelledToAffectedCountries,
        hasQuarantineOrder,
        hasContactWithCases,
        remarks,
      } = formData;

      const postData: any = {
        isTemperatureAboveThreshold,
        declarationFields: {
          hasSymptoms,
          isInOffice,
        },
      };

      if (isOthersRequired) {
        postData.declarationFields.hasTravelledToAffectedCountries = hasTravelledToAffectedCountries;
        postData.declarationFields.hasQuarantineOrder = hasQuarantineOrder;
        postData.declarationFields.hasContactWithCases = hasContactWithCases;
        postData.declarationFields.remarks = remarks;
      }

      setIsSubmitting(true);

      const endpoint =
        process.env.REACT_APP_TGW_API_ENDPOINT ||
        "https://temperature.api.muskgowhere.com";

      /*
        // Replaced in DWP-4559, enforce refreshig of access token before calling backend
      await axios.post(endpoint + "/declarations", postData, {
        withCredentials: true,
      });
      */
      await refreshAndPost(`${endpoint}/declarations`, postData, {
        withCredentials: true,
      });

      setIsSubmitting(false);

      replace({
        pathname: Routes.FORM_SUCCESS,
        search: queryString.stringify({ unwell: isOthersRequired }),
      });
    } catch (err) {
      setIsSubmitting(false);

      if (!err.response) {
        replace(Routes.CONNECTION_LOST);
        return;
      }

      if (err.response?.status === 401) {
        replace(Routes.SESSION_EXPIRED);
        return;
      }

      if (
        err.response?.status === 500 &&
        err.response?.data === "max number reached"
      ) {
        replace(Routes.LIMIT_EXCEEDED);
        return;
      }

      replace({
        pathname: Routes.FORM_FAILURE,
        search: queryString.stringify(err.response.data),
      });
    }
  };

  const renderAlert = () => {
    let hasErrors = false;
    Object.values(formErrors).forEach((value) => {
      if (value !== undefined) {
        hasErrors = true;
      }
    });

    return (
      hasErrors && (
        <Alert appearance={AlertAppearance.ERROR}>
          These questions require your attention!
        </Alert>
      )
    );
  };

  return (
    <ScreenFull data-testid="form-self-more-page" className="relative">
      <div className="absolute w-full">{renderAlert()}</div>
      <ContainerMobile className="min-h-full flex flex-col items-center justify-between pt-8 p-4">
        <div>
          <div className="w-full mb-6">
            <p className="mb-2">{staticData.hasSymptoms.questionV2}</p>
            <FieldRadioGroup
              name="hasSymptoms"
              value={formData.hasSymptoms}
              error={formErrors.hasSymptoms}
              options={staticData.hasSymptoms.options}
              onChange={handleChange}
            />
          </div>
          <div className="w-full mb-6">
            <p className="mb-2">{staticData.isInOffice.questionV2}</p>
            <FieldRadioGroup
              name="isInOffice"
              value={formData.isInOffice}
              error={formErrors.isInOffice}
              options={staticData.isInOffice.options}
              onChange={handleChange}
            />
          </div>
          <div className={isOthersRequired ? "block" : "hidden"}>
            <div className="w-full mb-6">
              <p className="mb-2">
                {staticData.hasTravelledToAffectedCountries.questionV2}
              </p>
              <FieldRadioGroup
                name="hasTravelledToAffectedCountries"
                value={formData.hasTravelledToAffectedCountries}
                error={formErrors.hasTravelledToAffectedCountries}
                options={staticData.hasTravelledToAffectedCountries.options}
                disabled={!isOthersRequired}
                onChange={handleChange}
              />
            </div>
            <div className="w-full mb-6">
              <p className="mb-2">{staticData.hasQuarantineOrder.questionV2}</p>
              <FieldRadioGroup
                name="hasQuarantineOrder"
                value={formData.hasQuarantineOrder}
                error={formErrors.hasQuarantineOrder}
                options={staticData.hasQuarantineOrder.options}
                disabled={!isOthersRequired}
                onChange={handleChange}
              />
            </div>
            <div className="w-full mb-6">
              <p className="mb-2">
                {staticData.hasContactWithCases.questionV2}
              </p>
              <FieldRadioGroup
                name="hasContactWithCases"
                value={formData.hasContactWithCases}
                error={formErrors.hasContactWithCases}
                options={staticData.hasContactWithCases.options}
                disabled={!isOthersRequired}
                onChange={handleChange}
              />
            </div>
            <div className="w-full mb-6">
              <label htmlFor="remarks" className="mb-2">
                Remarks
              </label>
              <FieldTextInput
                id="remarks"
                name="remarks"
                value={formData.remarks}
                disabled={!isOthersRequired}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-between">
          <Button
            align="left"
            appearance={ButtonAppearance.TEXT_GRAY}
            size={ButtonFontSize.LARGE}
            onClick={handleBackClick}
          >
            Back
          </Button>
          {isSubmitting ? (
            <SvgSpinner width="32" height="32" data-testid="spinner" />
          ) : (
            <Button
              align="right"
              appearance={ButtonAppearance.TEXT_BLUE}
              size={ButtonFontSize.LARGE}
              onClick={handleSubmitClick}
            >
              Submit
            </Button>
          )}
        </div>
      </ContainerMobile>
    </ScreenFull>
  );
};

const mapStateToProps: MapStateToProps<StateProps, unknown, RootState> = (
  state
) => {
  return {
    formData: state.form.formData,
    formErrors: state.form.formErrors,
    staticData: state.form.staticData,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, unknown> = {
  updateFormData,
  updateFormErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSelfMorePage);
