import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const formatDateTime = (dateString: string): string => {
  if (isNaN(Date.parse(dateString))) {
    return dateString;
  }

  const date = new Date(dateString);
  const zonedDate = utcToZonedTime(date, "Asia/Singapore");

  return format(zonedDate, "d MMM yyyy, h:mmaaaaa'm'");
};
